/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import Footer from '../components/Footer'
// import Logo from '../images/logo-icon.png'
import Logo1 from '../images/newLogo.png'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
// import Header from "./header"
import "./layout.css"
import { MdKeyboardArrowDown } from 'react-icons/md';

const Layout = ({ path, children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)
  const [isOpen, setIsOpen] = useState(false);
  const [isComOpen, setIsComOpen] = useState(false);
  // const [btnDropleft, setbBtnDropleft] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  console.log(path)
  var pathname = path;
  if (typeof pathname !== 'undefined') {
    pathname = pathname.toLowerCase()
  }

  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title}  pathName ={path}/> */}
      <header className="header_section">
        {/* <Container> */}
        <Navbar light expand="md">
          <NavbarBrand href="/">
            {/* <a href="/">
                <div className="logo_left">
                  <img src={Logo} />
                </div>
                <div className="logo_right">
                  <h2>Trident</h2>
                  <p>Trident Consulting</p>
                </div>
              </a> */}
            <img src={Logo1} width={90} alt="img" />

          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr_auto" navbar>
              <NavItem>
                <NavLink href="/" active={pathname === '/home/'}>Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/Diversity" active={pathname === '/diversity/'}>Diversity</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/Enterprise" active={pathname === '/enterprise/'}>Enterprise</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/Startups" active={pathname === '/startups/'}>Startups</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/Consultants" active={pathname === '/consultants/'}>Consultants</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/Jobs" active={pathname === '/job/'}>Jobs</NavLink>
              </NavItem>
              <NavItem>
                {/* <NavLink href="/Company" active={pathname === '/Company'}>Company</NavLink> */}
              </NavItem>
              <UncontrolledDropdown nav inNavbar active={pathname === '/socialresponsibility/'|| pathname === '/blog/' || pathname === '/whytrident/' || pathname === '/team/' || pathname === '/company/' || pathname === '/services/' || pathname === '/aboutceo/' || pathname === '/referfriend/' || pathname === '/referfriend/' || pathname === '/referclient/' || pathname === '/triumphday/' ||  pathname === '/bigdata/' || pathname === '/certifications/' || pathname === '/contact/'}
                onMouseOver={() => setIsComOpen(true)}
                onFocus={() => setIsComOpen(true)}
                onMouseLeave={() => setIsComOpen(false)}
                onBlur={() => setIsComOpen(false)}
                onClick={() => window.open("/Company", "_self")}
                isOpen={isComOpen}
                // isOpen={isOpen}
              >
                <DropdownToggle nav  > Company <MdKeyboardArrowDown /> </DropdownToggle>
                <DropdownMenu right>
                  {/* <DropdownItem href="/Company" active={pathname === '/company/'} >About Company</DropdownItem>
                    <DropdownItem divider /> */}
                  <DropdownItem></DropdownItem>
                  <DropdownItem href="/Company/Services" active={pathname === '/services/'}>Services</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/Company/WhyTrident" active={pathname === '/whytrident/'}>Why Trident</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/Company/Certifications" active={pathname === '/certifications/'} >Certifications</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/Company/Blog" active={pathname === '/blog/'} >Blog</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/Company/Team" active={pathname === '/team/'}>Team</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/Company/AboutCEO" active={pathname === '/aboutceo/'}>CEO Story</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="/Company/SocialResponsibility" active={pathname === '/socialresponsibility/'}>Social Responsibility</DropdownItem>
                  <DropdownItem divider />
                  {/* <DropdownItem href="/Company/ReferFriend" active={pathname === '/referfriend/'}>Refer a Friend</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem href="/Company/ReferClient" active={pathname === '/referclient/'}> Refer a Client</DropdownItem>
                    <DropdownItem divider /> */}
                  {/* <DropdownItem href="/Company/Jobs" active={pathname === '/job/'}>Job Search</DropdownItem>
                    <DropdownItem divider /> */}
                  <DropdownItem href="/Company/ContactUs" active={pathname === '/contact/'}>Contact us</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>
        {/* </Container> */}
      </header>


      <div>
        <main>{children}</main>
        {/* <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer> */}
        <Footer pathName={path} />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
