import React, { useState } from 'react'
import Layout from '../../components/layout'
import { Container, Row, Col, ModalBody, Modal } from 'reactstrap'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Img from "gatsby-image"
// import { IoIosInfinite } from 'react-icons/io';
// import { FaRegHandshake } from 'react-icons/fa';
// import { FiEye } from 'react-icons/fi';
// import { FiArrowRight } from 'react-icons/fi'
import Img1 from '../../images/svg/company1.svg'
import Img2 from '../../images/svg/company2.svg'
import Img3 from '../../images/svg/company3.svg'
import Fade from 'react-reveal/Fade';

import BlogForm from './Blog/BlogForm'

const Company = (props) => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <StaticQuery
            query={graphql`
      query {
        Banner: file(relativePath: { eq: "company_banner1.jpg" }) {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1220, maxHeight:400,) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          } 
        
      }
    `}

            render={data => {
                const imageData = data.Banner.childImageSharp.fluid
                const {
                    // buttonLabel,
                    className
                } = props;
                return (
                    <Layout path="/company/">
                        <section className="banner_min">
                            <div className="banner_section">
                                <Img
                                    fluid={imageData}
                                    className="startup_background"
                                ></Img>
                            </div>
                            <div className="banner_blur">
                                <div className="first_banner">
                                    <Container>
                                        <Row>
                                            <Col sm="12" md={{ size: 7, offset: 6 }}>
                                                <Fade right>
                                                    <div>
                                                        <h1>Trust<br />Transparency <br />Tenacity</h1>
                                                        {/* <h1>The three pillars of Trident are: Trust, Transparency, and Tenacity.</h1> */}
                                                    </div>
                                                </Fade>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </div>
                        </section>
                        <section className="startup_bg">
                            <div className="startup_section">
                                <Container>
                                    {/* <div className="text_center">
                                    <h3> "The three pillars of Trident are: "</h3>
                                </div> */}
                                    <Row>
                                        <Col md="2">
                                            <div className="section_img">
                                                <img src={Img1} alt="img" />
                                            </div>
                                        </Col>
                                        <Col md="10">
                                            <div className="startup_section_text">
                                                <h3>Trust: We Own Your Success</h3>
                                                <p>Our CEO, Shabana Siraj is a pilot-in-training, motorcycle, and fast car enthusiast. In all these, she truly appreciates that trust is the single element that keeps woman-and-machine in control. Shabana has inculcated trust in everything we do at Trident—from how we engage with new and existing clients, consultants, and partners. Each of us understand that at Trident, we own your success—whether you are a client or a consultant.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section>
                            <div className="startup_section">
                                <Container>
                                    <Row>
                                        <Col md={{ size: 10, order: 1 }} xs={{ order: 2 }}>
                                            <div className="startup_section_text">
                                                <h3>Transparency: Swift And Specific</h3>
                                                {/* <p>In today’s connected and flat-world ecosystem, all players are equal. By being completely transparent in all our engagements, we are able to move at speed and deliver very specific services that has led to our sustained growth. By owning our client’s project goals, we are able to transparently engage prospect candidates in very specific conversations, reducing our time-to-delivery and increasing our success ratio dramatically.</p> */}
                                                <p>In today’s connected and flat-world ecosystem, all players are equal. By being completely transparent in all our engagements, we are able to move swiftly and deliver very specific services that has led to our sustained growth. By owning our client’s project goals, we are able to transparently engage prospect candidates in very specific conversations, reducing our time-to-delivery and increasing our success ratio dramatically.</p>
                                            </div>
                                        </Col>
                                        <Col md={{ size: 2, order: 2 }} xs={{ order: 1 }}>
                                            <div className="section_img1">
                                                <img src={Img2} alt="img" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section className="startup_bg">
                            <div className="startup_section">
                                <Container>
                                    <Row>
                                        <Col md="2">
                                            <div className="section_img">
                                                <img src={Img3} alt="img" />
                                            </div>
                                        </Col>
                                        <Col md="10">
                                            <div className="startup_section_text">
                                                <h3>Tenacity: We Never Give Up</h3>
                                                {/* <p>Yup, we’ll be the last woman standing, and still working on that toughest requirement when everybody has gone home. We may not be successful all the time, but you are getting our best efforts every time. Trident believes in continuous learning, community-oriented, and growing as an employee-owned firm. We have expanded in tier 2 and tier 3 cities, allowing us to include more people into the technology workforce.</p> */}
                                                <p>Yup, we’ll be the last woman standing, and still working on that toughest requirement when everybody has gone home. We may not be successful all the time, but you are getting our best efforts every time. Trident believes in learning continuously, being community-oriented, and growing as an employee-owned firm. We have expanded in tier 2 and tier 3 cities, allowing us to include more people into the technology workforce.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </section>
                        <section className="footer_section">
                            <Container>
                                <Row>
                                    <Col md="12">
                                        <h1>Find out what Trident can do for you.</h1>
                                        <div className="footer_button">
                                            <a href onClick={toggle}>Contact Us</a>
                                        </div>
                                        <Modal isOpen={modal} toggle={toggle} className={className} className="form_modal">
                                            <ModalBody >
                                                <BlogForm title="Find out what Trident can do for you." close={()=> toggle()}/>
                                            </ModalBody>

                                        </Modal>
                                    </Col>
                                </Row>
                            </Container>
                        </section>

                        {/* <BlogForm refItem = {()=>{}} title = "Find out what Trident can do for you." redirectTitle="Company" pathname={props.location.pathname}/> */}


                    </Layout>
                )
            }}
        />
    )
}
const StyledBackgroundSection = styled(Company)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  margin: 0;
  background-color: inherit !important;
  
  `

export default StyledBackgroundSection